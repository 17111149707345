import React from 'react';
import aa from 'search-insights';
import cud from '../cud';
import {v4} from 'uuid';

if (cud) {
    aa('init', {
        appId: '7FR6O7XAAU',
        apiKey: 'a3f2cb1729632863b75db9ad0cd6f7d8',
        userToken: v4()
    });
    aa('onUserTokenChange', (token) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({algoliaUserToken: token});
    }, { immediate: true });
    aa('onAuthenticatedUserTokenChange', (token) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({algoliaAuthenticatedUserToken: token});
    });
}

export const AlgoliaStateContext = React.createContext(() => {});

export const AlgoliaStateProvider = React.memo(({ children }) => {
    const [state] = React.useState(() => aa);
    return (
        <AlgoliaStateContext.Provider value={state}>
            {children}
        </AlgoliaStateContext.Provider>
    );
});

export const useAlgoliaState = () => React.useContext(AlgoliaStateContext);