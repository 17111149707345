import 'isomorphic-fetch';
import Application from './components/Application';
import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import {hydrateRoot} from 'react-dom/client';
import {RelayEnvironmentProvider} from 'react-relay/hooks';
import createRelayEnvironment from './createRelayEnvironment';
import Cookies from 'js-cookie';
import {SubscriptionStateProvider} from './components/SubscriptionState';
import {AlgoliaStateProvider} from './components/algolia-state';
import {AuthorizationProvider} from './contexts/Authorization'
import {CartProvider} from './contexts/cart';
import {InstantProvider} from './contexts/instant';
import CheckoutProvider from './components/CheckoutProvider';
import DialogProvider from './components/DialogProvider';
import {HelmetProvider} from 'react-helmet-async';
import {MoveProvider} from './components/Move';
import {ScanSettingsProvider} from './pages/scan/settings-context';
import {ScrollProvider} from './contexts/scroll';

const store = {
    get: function(key) {return Cookies.get(key);},
    set: function(key, value) {Cookies.set(key, value, {expires: 365, path: '/'});},
    remove: function(key) {Cookies.remove(key, {path: '/'});},
};

hydrateRoot(document.getElementById('root'),
    <RelayEnvironmentProvider environment={createRelayEnvironment(
        () => {
                let headers = {'Content-Type': 'application/json'};
                if (window?.CookieConsentApi?.allowedCategory('analytics') && Cookies.get('_fbp')) {
                    headers = {...headers, 'Fbp': Cookies.get('_fbp')};
                }
                if (window?.CookieConsentApi?.allowedCategory('analytics') && Cookies.get('_fbc')) {
                    headers = {...headers, 'Fbc': Cookies.get('_fbc')};
                }
                return headers;
            }, store
    )}>
        <BrowserRouter>
            <HelmetProvider>
                <AlgoliaStateProvider>
                    <AuthorizationProvider {...{store}}>
                        <SubscriptionStateProvider>
                            <MoveProvider>
                                <CartProvider>
                                    <InstantProvider>
                                        <CheckoutProvider>
                                            <DialogProvider>
                                                <ScanSettingsProvider>
                                                    <ScrollProvider>
                                                        <Application/>
                                                    </ScrollProvider>
                                                </ScanSettingsProvider>
                                            </DialogProvider>
                                        </CheckoutProvider>
                                    </InstantProvider>
                                </CartProvider>
                            </MoveProvider>
                        </SubscriptionStateProvider>
                    </AuthorizationProvider>
                </AlgoliaStateProvider>
            </HelmetProvider>
        </BrowserRouter>
    </RelayEnvironmentProvider>
);

if (module.hot) {
    module.hot.accept();
}
