import React from 'react';
import usePageVisibility from '../usePageVisibility';
import { useAlgoliaState } from '../components/algolia-state';

export const AuthorizationStateContext = React.createContext({});
export const AuthorizationDispatchContext = React.createContext(() => {});
export const AuthorizationStoreContext = React.createContext({});

export const tokenType__ = 'tokenType';
export const accessToken__ = 'accessToken';
export const refreshToken__ = 'refreshToken';

const jwtDecode = (token) => {
    if (!token) {
        return null;
    }
    const payload = token.split('.')[1];
    const decoded = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));
    return JSON.parse(decoded);
};

const getState = (store, aa) => {
    const state = !!store.get(tokenType__);
    if (state) {
        aa('setAuthenticatedUserToken', jwtDecode(store.get(accessToken__))?.sub);
    }
    return state;
};

export const AuthorizationProvider = React.memo(({children, store}) => {
    const aa = useAlgoliaState();
    const [state, setState] = React.useState(() => getState(store, aa));
    const onVisibilityChange = React.useCallback((visible) => {
        if (visible) {
            setState(getState(store, aa));
        }
    }, []);
    usePageVisibility(onVisibilityChange);
    const dispatch = React.useCallback(() => {
        setState(getState(store, aa));
    }, []);
    return (
        <AuthorizationStoreContext.Provider value={store}>
            <AuthorizationStateContext.Provider value={state}>
                <AuthorizationDispatchContext.Provider value={dispatch}>
                    {children}
                </AuthorizationDispatchContext.Provider>
            </AuthorizationStateContext.Provider>
        </AuthorizationStoreContext.Provider>
    );
});

export const useAuthorizationState = () => React.useContext(AuthorizationStateContext);
export const useAuthorizationDispatch = () => React.useContext(AuthorizationDispatchContext);
export const useAuthorizationStore = () => React.useContext(AuthorizationStoreContext);